import { all, takeEvery, put, call, select } from "redux-saga/effects";
import projectPaymentsActions from "./actions";
import projectitemsActions from '../projectitems/actions';
import { rsf, db } from '@iso/lib/firebase/firebase';
import { getCurrentUserEmail } from '@iso/lib/firebase/firebase.authentication.util';
import { createNewPayment, clonePayment } from '@iso/lib/remod-helpers/globalHelpers';
import {
  PROJECT_TABS
} from '@iso/lib/remod-helpers/globalEnumsAndConsts';

function* loadProjectPaymentsSaga({ payload }) {
  let payments = {};
  try {
    const queryReference = db
      .collection("payments")
      .where("projectId", "==", payload.projectId);

    const projectItemsSnapshot = yield call(
      rsf.firestore.getCollection,
      queryReference
    );

    projectItemsSnapshot.forEach((payment) => {
      payments = {
        ...payments,
        [payment.id]: payment.data(),
      };
    });
    yield put(
      projectPaymentsActions.createLoadProjectPaymentsSuccessAction({
        projectPayments: payments,
      })
    );
  } catch (error) {
    yield put(
      projectPaymentsActions.createLoadProjectPaymentsFailedAction({
        projectPaymentsError: error,
      })
    );
  }
}

function* appendPaymentToProjectItem (projectItemId, paymentId) {
  const projectItemSnapshot = yield call(
    rsf.firestore.getDocument,
    'projectitems/' + projectItemId
  );
  let projectItemDetails = projectItemSnapshot.data();
  projectItemDetails.paymentId = paymentId;
  yield call(rsf.firestore.setDocument, 'projectitems/' + projectItemId, projectItemDetails);
}

const getCurrentTab = (state) => {
  return state.projects.selectedTab;
};

function* createProjectPaymentSaga({ payload }) {
  const selectedTab = yield select(getCurrentTab);
  const loggedInUserEmail = yield call(getCurrentUserEmail);
  const projectItemId = payload.projectItemId;
  const projectItemIds = payload.projectItems;
  payload.createdBy = loggedInUserEmail;
  payload.lastModifiedBy = loggedInUserEmail;
  const projectPayment = clonePayment(payload);
  const paymentId = db.collection('projectitems').doc().id;
  try {
    yield call(rsf.firestore.setDocument, "payments/" + paymentId, projectPayment);
    if (projectItemId !== undefined && projectItemId !== '') {
      yield call (appendPaymentToProjectItem, projectItemId, paymentId);
    }
    if (projectItemIds !== undefined && Array.isArray(projectItemIds) && projectItemIds.length > 0) {
      yield all(projectItemIds.map(id => call(appendPaymentToProjectItem, id, paymentId)));
    }
    if (PROJECT_TABS.PLANNED_BUDGET === selectedTab) {
      yield put(
        projectPaymentsActions.createCreateProjectPaymentSuccessAction({
          paymentSuccessMessage: "Payment added",
        })
      );
    }
    yield put(
      projectPaymentsActions.createCreateProjectPaymentSuccessAction({
        paymentSuccessMessage: "",
      })
    );
    yield put(
      projectPaymentsActions.createLoadProjectPaymentsStartAction(
        { projectId: payload.projectId }
      )
    );
    yield put(
      projectitemsActions.createLoadProjectitemsOfProjectActionStart({
          projectId: payload.projectId,
      })
    );
  } catch (error) {
    yield put(
      projectPaymentsActions.createCreateProjectPaymentFailedAction({
        paymentErrorMessage: error,
      })
    );
  }
}

function* batchCreateProjectPaymentsSaga({ payload }) {
  try {
      const loggedInUserEmail = yield call(getCurrentUserEmail);
      const batch = db.batch();
      const paymentsCollectionRef = db.collection('payments');

      payload.projectPayments.forEach((payment) => {
          const paymentRef = paymentsCollectionRef.doc();
          const newPayment = createNewPayment(
              payload.projectId,
              loggedInUserEmail,
          );
          batch.set(paymentRef, {
              ...newPayment,
              name: payment.name,
              description: payment.description,
              paymentSource: payment.paymentSource,
              projectItemId: payment.projectItemId,
              value: payment.value
          });
      });

      yield call([batch, batch.commit]);
      yield put(
        projectPaymentsActions.createCreateProjectPaymentSuccessAction({
          paymentSuccessMessage: "Payment created",
        })
      );
  } catch (error) {
    yield put(
      projectPaymentsActions.createCreateProjectPaymentFailedAction({
        paymentErrorMessage: error,
      })
    );
  }
}

function* clearProjectPaymentsFlags() {
  yield put(
      projectitemsActions.createCreateProjectitemActionSuccess({
        projectPaymentsError: '',
        projectPaymentsSuccess: '',
      })
  );
}

function* updateProjectPaymentSaga({ payload }) {
  const loggedInUserEmail = yield call(getCurrentUserEmail);
  const paymentId = payload.key;
  const projectItemIds = payload.projectItems;
  const unassignedProjectItemIds = payload.unassignedProjectItems;
  payload.createdBy = payload.createdBy ?? loggedInUserEmail;
  payload.lastModifiedBy = loggedInUserEmail;
  payload.lastModifiedAt = undefined;
  const payment = clonePayment(payload);
  try {
    yield call(rsf.firestore.setDocument, "payments/" + paymentId, payment);
    if (projectItemIds !== undefined && Array.isArray(projectItemIds) && projectItemIds.length > 0) {
      yield all(projectItemIds.map(id => call(appendPaymentToProjectItem, id, paymentId)));
    }
    if (unassignedProjectItemIds !== undefined && Array.isArray(unassignedProjectItemIds) && unassignedProjectItemIds.length > 0) {
      yield all(unassignedProjectItemIds.map(id => call(appendPaymentToProjectItem, id, '')));
    }
    yield put(
      projectPaymentsActions.createUpdateProjectPaymentSuccessAction({
        paymentSuccessMessage: "",
      })
    );
    yield put(
      projectPaymentsActions.createLoadProjectPaymentsStartAction(
        { projectId: payload.projectId }
      )
    );
    yield put(
      projectitemsActions.createLoadProjectitemsOfProjectActionStart({
          projectId: payload.projectId,
      })
    );
  } catch (error) {
    yield put(
      projectPaymentsActions.createUpdateProjectPaymentFailedAction({
        projectitemsError: error,
      })
    );
  }
}

function* deleteProjectPaymentSaga({ payload }) {
  const projectPaymentId = payload.projectPaymentId;
  const unassignedProjectItemIds = payload.unassignedProjectItems;
  try {
    yield call(rsf.firestore.deleteDocument, "payments/" + projectPaymentId);
    if (unassignedProjectItemIds !== undefined && Array.isArray(unassignedProjectItemIds) && unassignedProjectItemIds.length > 0) {
      yield all(unassignedProjectItemIds.map(id => call(appendPaymentToProjectItem, id, '')));
    }
    yield put(
      projectPaymentsActions.createDeleteProjectPaymentSuccessAction({
        paymentSuccessMessage: "",
      })
    );
    yield put(
      projectPaymentsActions.createLoadProjectPaymentsStartAction(
        { projectId: payload.projectId }
      )
    );
    yield put(
      projectitemsActions.createLoadProjectitemsOfProjectActionStart({
          projectId: payload.projectId,
      })
    );
  } catch (error) {
    yield put(
      projectPaymentsActions.createDeleteProjectPaymentFailedAction({
        projectitemsError: error,
      })
    );
  }
}

export default function* projectPaymentsSaga() {
  yield all([
    takeEvery(
      projectPaymentsActions.LOAD_PROJECTPAYMENTS_START,
      loadProjectPaymentsSaga
    ),
    takeEvery(
      projectPaymentsActions.CREATE_PROJECTPAYMENT_START,
      createProjectPaymentSaga
    ),
    takeEvery(
      projectPaymentsActions.BATCH_CREATE_PROJECTPAYMENT_START, 
      batchCreateProjectPaymentsSaga),
    takeEvery(
      projectPaymentsActions.UPDATE_PROJECTPAYMENT_START,
      updateProjectPaymentSaga
    ),
    takeEvery(
      projectPaymentsActions.DELETE_PROJECTPAYMENT_START,
      deleteProjectPaymentSaga
    ),
    takeEvery(projectPaymentsActions.CLEAR_PROJECTPAYMENTS_FLAGS, clearProjectPaymentsFlags),
  ]);
}
