import { all, takeEvery, put, call } from 'redux-saga/effects';
import projectsActions from './actions';
import { rsf, db } from '@iso/lib/firebase/firebase';
import {
    isLoggedInUserAContractor,
    isLoggedInUserAHomeOwner,
} from '@iso/lib/remod-helpers/localStorage';
import { getCurrentUserEmail } from '@iso/lib/firebase/firebase.authentication.util';
import { getCostsDataForProject } from '@iso/lib/remod-helpers/projectCostsCalculationUtils';
import emailjs from '@emailjs/browser';
import { PUBLIC_ROUTE } from './../../route.constants';
import { cloneDeep } from 'lodash';
import {
    createNewProjectItem,
    createNewProject,
    cloneProject,
    formatCurrencyValue,
    cloneContractor
} from '@iso/lib/remod-helpers/globalHelpers';
import { defaultOwnerEmailAddress } from '@iso/lib/remod-helpers/globalEnumsAndConsts';
import projectPaymentActions from '@iso/redux/projectPayments/actions';
import { splitArrayIntoMultipleArraysForSagaQuery } from '@iso/lib/remod-helpers/globalHelpers';

import firebase from 'firebase/app';

function* loadProjectsPayments(projectIds, inputArray) {
    let payments = {};
    let multipleArrays = splitArrayIntoMultipleArraysForSagaQuery(projectIds);
    for (let i = 0; i < multipleArrays.length; i++) {
        const paymentsCollectionReference = db
            .collection('payments')
            .where('projectId', 'in', multipleArrays[i]);
        const paymentsSnapshot = yield call(
            rsf.firestore.getCollection,
            paymentsCollectionReference
        );
        paymentsSnapshot.docs.forEach((payment) => {
            payments[payment.id] = payment.data();
        });
    }
    inputArray.push(payments);
}

function* loadProjectsItems(projectIds, inputArray) {
    let projectItems = {};
    let multipleArrays = splitArrayIntoMultipleArraysForSagaQuery(projectIds);
    for (let i = 0; i < multipleArrays.length; i++) {
        const projectItemsCollectionReference = db
            .collection('projectitems')
            .where('projectId', 'in', multipleArrays[i]);
        const projectItemsSnapshot = yield call(
            rsf.firestore.getCollection,
            projectItemsCollectionReference
        );
        projectItemsSnapshot.docs.forEach((projectItem) => {
            projectItems[projectItem.id] = projectItem.data();
        });
    }
    inputArray.push(projectItems);
}

function* loadContractors(projectIds, inputArray) {
    let contractors = {};
    let multipleArrays = splitArrayIntoMultipleArraysForSagaQuery(projectIds);
    for (let i = 0; i < multipleArrays.length; i++) {
        const projectItemsCollectionReference = db
            .collection('contractors')
            .where('projectId', 'in', multipleArrays[i]);
        const projectItemsSnapshot = yield call(
            rsf.firestore.getCollection,
            projectItemsCollectionReference
        );
        projectItemsSnapshot.docs.forEach((contractor) => {
            contractors[contractor.id] = contractor.data();
        });
    }
    inputArray.push(contractors);
}

function* loadProjectsDepenedOnRoleNew() {
    let projects = {};
    const isOwner = isLoggedInUserAHomeOwner();
    const isContractor = isLoggedInUserAContractor();
    const loggedInUserEmail = yield call(getCurrentUserEmail);
    if (isOwner) {
        projects = yield call(loadProjectsForOwner, loggedInUserEmail);
    } else if (isContractor) {
        projects = yield call(loadProjectForContractor, loggedInUserEmail);
    }
    return projects;
}

function* loadProjectForContractor(loggedInUserEmail) {
    let projects = {};
    const uniqueProjectIds = new Set();
    let projectsCollectionReference = db.collection('projects').where('createdBy', '==', loggedInUserEmail);
    let projectSnapshot = yield call(rsf.firestore.getCollection, projectsCollectionReference);
    if (projectSnapshot.docs.length === 0) {
        projects = {};
    }
    projectSnapshot.docs.forEach((project) => {
        uniqueProjectIds.add(project.id);
        projects = {
            ...projects,
            [project.id]: project.data(),
        };
    });
    const contractorsCollectionReference = db
        .collection('contractors')
        .where('email', '==', loggedInUserEmail);
    const contractorsSnapshot = yield call(
        rsf.firestore.getCollection,
        contractorsCollectionReference
    );
    const uniqueProjectIdsFromContractor = new Set();
    contractorsSnapshot.docs.forEach((contractor) => {
        if (contractor.data().projectId !== undefined) {
            uniqueProjectIdsFromContractor.add(contractor.data().projectId);
        }
    });
    const projectIdsFromContractor = Array.from(uniqueProjectIdsFromContractor).filter(
        (item) => !Array.from(uniqueProjectIds).includes(item)
    );
    let multipleArrays = splitArrayIntoMultipleArraysForSagaQuery(projectIdsFromContractor);
    for (let i = 0; i < multipleArrays.length; i++) {
        projectsCollectionReference = db.collection('projects').where(firebase.firestore.FieldPath.documentId(), 'in', multipleArrays[i]);
        projectSnapshot = yield call(rsf.firestore.getCollection, projectsCollectionReference);
        projectSnapshot.docs.forEach((project) => {
            projects[project.id] = project.data();
        });
    }
    return projects;
}

function* loadProjectsForOwner(loggedInUserEmail) {
    let projects = {};
    const projectsCollectionReference = db.collection('projects').where('ownerId', '==', loggedInUserEmail);
    const projectSnapshot = yield call(rsf.firestore.getCollection, projectsCollectionReference);
    projectSnapshot.docs.forEach((project) => {
        projects = {
            ...projects,
            [project.id]: project.data(),
        };
    });
    return projects;
}

function* loadUserProjectsSaga({ payload }) {
    let payments = {};
    let projectItems = {};
    let contractors = {};
    let projectIds = [];
    let filteredResult = {};

    try {
        const isOwner = isLoggedInUserAHomeOwner();
        const isContractor = isLoggedInUserAContractor();
        if (!isOwner && !isContractor) {
            yield put(
                projectsActions.createLoadUserProjectsActionFailed({
                    error: 'User has no valid role',
                    loaded: true,
                })
            );
            return;
        }
        filteredResult = yield call(loadProjectsDepenedOnRoleNew);
        projectIds = Object.keys(filteredResult).map((id) => {
            return id;
        });
        if (projectIds.length > 0) {
            const paymentsArray = [];
            yield call(loadProjectsPayments, projectIds, paymentsArray);
            payments = paymentsArray[0];
            const projectItemsArray = [];
            yield call(loadProjectsItems, projectIds, projectItemsArray);
            projectItems = projectItemsArray[0];
            const contractorsArray = [];
            yield call(loadContractors, projectIds, contractorsArray);
            contractors = contractorsArray[0];
        }
        Object.keys(filteredResult).map((id) => {
            const projectItemsFiltered =  Object.keys(projectItems)
            .filter(projectItemId => projectItems[projectItemId].projectId === id)
            .reduce((obj, key) => {
                obj[key] = projectItems[key];
                return obj;
            }, {});
            const paymentsFiltered = Object.keys(payments)
            .filter(paymentId => payments[paymentId].projectId === id)
            .reduce((obj, key) => {
                obj[key] = payments[key];
                return obj;
            }, {});
            const contractorsFiltered = Object.keys(contractors)
                .filter(key => contractors[key].projectId === id)
                .reduce((obj, key) => {
                    obj[key] = contractors[key];
                    return obj;
                }, {});
            filteredResult[id] = {
                ...filteredResult[id],
                ...getCostsDataForProject(
                    projectItemsFiltered,
                    paymentsFiltered,
                    contractorsFiltered
                ),
            };
        });
        yield put(
            projectsActions.createLoadUserProjectsActionSuccess({
                list: filteredResult,
                loaded: true,
            })
        );
    } catch (error) {
        yield put(
            projectsActions.createLoadUserProjectsActionFailed({
                error: error,
                loaded: true,
            })
        );
    }
}

function* createProjectSaga({ payload }) {
    try {
        const project = cloneProject(payload);
        const ref = db.collection('projects').doc();
        const projectSnapshot = yield call(rsf.firestore.setDocument, 'projects/' + ref.id, project);
        const projectDetails = projectSnapshot.data();
        projectDetails.id = ref.id;

        yield put(
            projectsActions.createCreateProjectActionSuccess({
                success: 'Project added',
                selectedProject: projectDetails,
            })
        );
    } catch (error) {
        yield put(projectsActions.createCreateProjectActionFailed({ error: error }));
    }
}

//TODO: update code below
function* createProjectByWizardSaga({ payload }) {
    try {
        const loggedInUserEmail = yield call(getCurrentUserEmail);
        const ref = db.collection('projects').doc();
        const project = payload.project;
        const userId = loggedInUserEmail !== undefined ? loggedInUserEmail : defaultOwnerEmailAddress;
        yield call(rsf.firestore.setDocument, 'projects/' + ref.id, 
        createNewProject(
            userId,
            project?.projectname ?? 'New Project',
            project?.description ?? '',
            project?.status ?? 'proposal',
            project?.projectPurpose ?? '',
            project?.address ?? ''
        ));
        if (isLoggedInUserAContractor()) {
            yield call(assignCoForLoggedContractorSaga, { projectId: ref.id });
        }
        yield put(
            projectsActions.createCreateProjectByWizardActionSuccess({
                success: 'Project added',
                selectedProject: { id: ref.id },
            })
        );
    } catch (error) {
        yield put(projectsActions.createCreateProjectByWizardActionFailed({ error: error }));
    }
}

function* assignCoForLoggedContractorSaga(payload) {
    const loggedInUserEmail = yield call(getCurrentUserEmail);
    const user = yield call(getUserByEmail, { email: loggedInUserEmail });
    const contractorToAssign = {
        name: user.firstname + ' ' + user.lastname,
        email: loggedInUserEmail,
        title: user.firstname + ' ' + user.lastname,
        projectId: payload.projectId,
        createdBy: loggedInUserEmail,
        lastModifiedBy: loggedInUserEmail
    };
    const contractor = cloneContractor(contractorToAssign);
    const id = db.collection('contractors').doc().id;
    yield call(rsf.firestore.setDocument, 'contractors/' + id, contractor);
}

function* assignHoSaga({ payload }) {
    try {
        const projectSnapshot = yield call(
            rsf.firestore.getDocument,
            'projects/' + payload.projectId
        );
        const projectDetails = projectSnapshot.data();
        projectDetails.id = projectSnapshot.id;
        projectDetails.ownerId = payload.ownerId?.toLowerCase();
        yield call(editProjectSaga, { payload: projectDetails });
    } catch (error) {
        yield put(projectsActions.createEditProjectActionFailed({ error: error }));
    }
}

function* editProjectSaga({ payload }) {
    const loggedInUserEmail = yield call(getCurrentUserEmail);
    const projectId = payload.id;
    payload.createdBy = payload.createdBy ?? loggedInUserEmail;
    payload.lastModifiedBy = loggedInUserEmail;
    payload.lastModifiedAt = undefined;
    const project = cloneProject(payload);
    try {
        const user = yield call(getUserByEmail, { email: project.ownerId });
        if (user === undefined && project.ownerId !== '') {
            yield call(sendEmailForHomeOwner, { email: project.ownerId });
            yield call(rsf.firestore.setDocument, 'projects/' + projectId, project);
            yield put(
                projectsActions.createEditProjectActionSuccess({
                    success: 'HomeOwner has been invited',
                })
            );
        }
        else if (user?.role === 'contractor') {
            yield put(projectsActions.createEditProjectActionFailed({ error: { message: 'User is not a HomeOwner' } }));
            yield put(projectsActions.createEditProjectActionFailed({ error: '' }));
            return;
        } else {
            yield call(rsf.firestore.setDocument, 'projects/' + projectId, project);
        }
        yield put(
            projectsActions.createEditProjectActionSuccess({
                success: '',
            })
        );
        yield put(projectsActions.createLoadProjectDetailsActionStart({
            projectId: projectId,
        }));
    } catch (error) {
        yield put(projectsActions.createEditProjectActionFailed({ error: error }));
    }
}

function* getUserByEmail(payload) {
    let userResult = undefined;
    const collectionReference = db
        .collection('users')
        .where('email', '==', payload.email.toLowerCase());
    const usersSnapshot = yield call(rsf.firestore.getCollection, collectionReference);
    usersSnapshot.forEach((user) => {
        userResult = { ...user.data(), key: user.id };
    });
    return userResult;
}

function* sendEmailForHomeOwner(payload) {
    const loggedInUserEmail = yield call(getCurrentUserEmail);
    const linkSnapshot = yield call(rsf.firestore.addDocument, 'links', {
        email: payload.email,
        role: 'owner',
    });
    linkSnapshot.get().then((link) => {
        const linkUrl = createLinkUrl(link.id);
        const params = {};
        params['linkUrl'] = linkUrl;
        params['email'] = link.data().email;
        params['inviterEmail'] = loggedInUserEmail;
        emailjs
            .send(
                process.env.REACT_APP_EMAIL_SERVICE_ID,
                process.env.REACT_APP_EMAIL_INVITATION_TEMPLATE_ID,
                params,
                process.env.REACT_APP_EMAIL_API_KEY
            )
            .then(
                (result) => {
                    console.log(result.text);
                },
                (error) => {
                    throw Error('Error with sending email invitation');
                }
            );
    });
}

function createLinkUrl(id) {
    const baseHost = process.env.REACT_APP_HOST_URL;
    return baseHost + PUBLIC_ROUTE.SIGN_UP + '/' + id;
}

function* deleteProjectSaga({ payload }) {
    try {
        const projectItemIds = [];
        const contractorIds = [];
        const paymentIds = [];
        const projectItemsCollectionReference = db
            .collection('projectitems')
            .where('projectId', '==', payload.projectId);
        const projectItemsSnapshot = yield call(
            rsf.firestore.getCollection,
            projectItemsCollectionReference
        );
        projectItemsSnapshot.docs.forEach((projectItem) => {
            projectItemIds.push(projectItem.id);
        });
        const contractorsCollectionReference = db
            .collection('contractors')
            .where('projectId', '==', payload.projectId);
        const contractorsSnapshot = yield call(
            rsf.firestore.getCollection,
            contractorsCollectionReference
        );
        contractorsSnapshot.docs.forEach((contractor) => {
            contractorIds.push(contractor.id);
        })
        const paymentsCollectionReference = db
            .collection('payments')
            .where('projectId', '==', payload.projectId);
        const paymentsSnapshot = yield call(
            rsf.firestore.getCollection,
            paymentsCollectionReference
        );
        paymentsSnapshot.docs.forEach((payment) => {
            paymentIds.push(payment.id);
        });
        yield all(projectItemIds.map((projectItemId) => call(deleteProjectItem, projectItemId)));
        yield all(paymentIds.map((paymentId) => call(deletePayment, paymentId)));
        yield all(contractorIds.map((contractorId) => call(deleteContractor, contractorId)));
        yield call(rsf.firestore.deleteDocument, 'projects/' + payload.projectId);
        yield put(
            projectsActions.createDeleteProjectActionSuccess({
                success: 'Project deleted',
            })
        );
    } catch (error) {
        yield put(
            projectsActions.createDeleteProjectActionFailed({
                error: error,
            })
        );
    }
}

function* deleteProjectItem(id) {
    yield call(rsf.firestore.deleteDocument, 'projectitems/' + id);
}

function* deleteContractor(id) {
    yield call(rsf.firestore.deleteDocument, 'contractors/' + id);
}

function* deletePayment(id) {
    yield call(rsf.firestore.deleteDocument, 'payments/' + id);
}

function* synchProjectSaga({ payload }) {
    let ownerResult = undefined;
    let ownerId;
    try {
        const collectionReference = db
            .collection('users')
            .where('email', '==', payload.email)
            .where('role', '==', 'owner');
        const contractorSnapshot = yield call(rsf.firestore.getCollection, collectionReference);
        contractorSnapshot.docs.forEach((owner) => {
            ownerResult = {
                ...ownerResult,
                [owner.id]: owner.data(),
            };
        });
        ownerId = payload.email;
        if (ownerResult === undefined) {
            yield call(sendEmailForOwner, payload);
        }
        const projectSnapshot = yield call(
            rsf.firestore.getDocument,
            'projects/' + payload.projectId
        );
        let project = projectSnapshot.data();
        project.ownerId = ownerId;
        yield call(rsf.firestore.setDocument, 'projects/' + payload.projectId, project);
        yield put(
            projectsActions.createSyncProjectSuccess({
                syncSuccess:
                    ownerResult === undefined
                        ? 'Email sent with invitation to homeowner'
                        : 'Project Synched',
            })
        );
    } catch (error) {
        yield put(projectsActions.createSyncProjectFailed({ syncError: error }));
    }
}

function* sendEmailForOwner(payload) {
    const loggedInUserEmail = yield call(getCurrentUserEmail);
    const linkSnapshot = yield call(rsf.firestore.addDocument, 'links', {
        email: payload.email,
        role: 'owner',
    });
    linkSnapshot.get().then((link) => {
        const linkUrl = createLinkUrl(link.id);
        const params = {};
        params['linkUrl'] = linkUrl;
        params['email'] = link.data().email;
        params['inviterEmail'] = loggedInUserEmail;
        emailjs
            .send(
                process.env.REACT_APP_EMAIL_SERVICE_ID,
                process.env.REACT_APP_EMAIL_INVITATION_TEMPLATE_ID,
                params,
                process.env.REACT_APP_EMAIL_API_KEY
            )
            .then(
                (result) => {
                    console.log(result.text);
                },
                (error) => {
                    throw Error('Error with sending email invitation');
                }
            );
    });
}

function* createCategoryProjectSaga({ payload }) {
    const projectId = payload.project.id;
    delete payload.project['id'];
    try {
        yield call(rsf.firestore.setDocument, 'projects/' + projectId, payload.project);
        yield put(
            projectsActions.createCreateCategoryProjectActionSuccess({
                success: 'Category added',
            })
        );
    } catch (error) {
        yield put(projectsActions.createCreateCategoryProjectActionFailed({ error: error }));
    }
}

function* createSetActionNameSaga({ payload }) {
    yield put(
        projectsActions.createSetActionNameSuccess({
            actionName: payload.actionName,
        })
    );
}

function* clearFlags() {
    yield put(
        projectsActions.createCreateProjectActionSuccess({
            success: '',
            error: '',
            syncSuccess: '',
            syncError: '',
            actionName: '',
            loaded: false,
        })
    );
}

function* clearSelectedProject() {
    yield put(projectsActions.clearSelectedProjectSuccess({ selectedProject: {} }));
}

function* loadProjectDetailsSaga({ payload }) {
    try {
        const projectSnapshot = yield call(
            rsf.firestore.getDocument,
            'projects/' + payload.projectId
        );
        const projectDetails = projectSnapshot.data();
        projectDetails.id = payload.projectId;
        yield put(
            projectsActions.createLoadProjectDetailsActionSuccess({
                selectedProject: projectDetails,
            })
        );
    } catch (error) {
        yield put(projectsActions.createLoadProjectDetailsActionFailed({ error: error }));
    }
}

function* createSetSelectedTabSaga({ payload }) {
    yield put(
        projectsActions.createSetSelectedTabSuccess({
            selectedTab: payload.selectedTab,
        })
    );
}

function* createProjectWithCalculationSaga({ payload }) {
    const loggedInUserEmail = yield call(getCurrentUserEmail);
    const userId = loggedInUserEmail !== undefined ? loggedInUserEmail : defaultOwnerEmailAddress;
    const projectItems = payload.items;
    const selectedProjectTemplate = payload.selectedProjectTemplate;

    try {
        const ref = db.collection('projects').doc();
        const projectId = ref.id;
        yield call(
            rsf.firestore.setDocument,
            'projects/' + projectId,
            createNewProject(
                userId,
                selectedProjectTemplate?.projectname ?? 'New Project',
                selectedProjectTemplate?.description ?? '',
                selectedProjectTemplate?.status ?? 'proposal',
                selectedProjectTemplate?.projectPurpose ?? '',
                selectedProjectTemplate?.address ?? ''
            )
        );

        const batch = db.batch();
        const projectItemsCollectionRef = db.collection('projectitems');

        if(isLoggedInUserAContractor()) {
            const contractorCategory = projectItems.find(x => x.contractorId === loggedInUserEmail)
            const contractorCategoryToSave = createNewProjectItem(projectId, userId, userId, '', contractorCategory.name);
            contractorCategoryToSave.costType = contractorCategory.costType;
            const categoryContractorId = db.collection('projectitems').doc().id;
            yield call(rsf.firestore.setDocument, 'projectitems/' + categoryContractorId, contractorCategoryToSave);
            if (contractorCategory.projectPayments) {
                contractorCategory.projectPayments.forEach(payment => payment.projectItemId = categoryContractorId);
                yield put(projectPaymentActions.createBatchCreateProjectPaymentsActionStart(contractorCategoryToSave.projectId, contractorCategory.projectPayments));
            }
            projectItems
            .filter(x => x.costType === 'labor')
            .filter(x => x.contractorId === '' || x.contractorId === undefined)
            .forEach((item) => {
                const projectItemRef = projectItemsCollectionRef.doc();
                const newProjectItem = createNewProjectItem(projectId, userId, '', '');
                batch.set(projectItemRef, {
                    ...newProjectItem,
                    name: item.name,
                    description: item.description,
                    position: item.position,
                    contractorId: userId,
                    parentProjectItemId: categoryContractorId,
                    categoryId: item.categoryId,
                    cost: formatCurrencyValue(item.cost),
                    costType: item.costType ?? 'materials',
                    minTotalCost: formatCurrencyValue(item.minTotalCost),
                    maxTotalCost: formatCurrencyValue(item.maxTotalCost),
                });
            });
        }

        if (isLoggedInUserAHomeOwner()) {
            projectItems
                .filter(
                    (x) => x.costType === 'materials' || ['materials', 'labor'].includes(x.costType)
                )
                .filter((x) => x.averageUnitCost !== 0)
                .forEach((item) => {
                    const projectItemRef = projectItemsCollectionRef.doc();
                    const newProjectItem = createNewProjectItem(projectId, userId, '', '');
                    batch.set(projectItemRef, {
                        ...newProjectItem,
                        name: item.name,
                        description: item.description,
                        cost: formatCurrencyValue(item.cost),
                        costType: item.costType ?? 'labor',
                        categoryId: item.categoryId,
                        minTotalCost: formatCurrencyValue(item.minTotalCost),
                        maxTotalCost: formatCurrencyValue(item.maxTotalCost),
                    });
                });
        }

        yield call([batch, batch.commit]);

        const projectSnapshot = yield call(rsf.firestore.getDocument, 'projects/' + projectId);
        const projectDetails = projectSnapshot.data();
        projectDetails.id = projectId;

        yield put(
            projectsActions.createProjectWithCalculationActionSuccess({
                selectedProject: projectDetails,
            })
        );
    } catch (error) {
        yield put(
            projectsActions.createProjectWithCalculationActionFailed({
                error: `Error creating project cost structure. Error details: ${error}`,
            })
        );
    }
}

function* assignAnonymousProjectToCurrentUser({ payload }) {
    const projectId = payload.projectId;
    const ownerId = payload.ownerId;
    try {
        // Update project document
        yield call(rsf.firestore.updateDocument, `projects/${projectId}`, {
            createdBy: ownerId,
            lastModifiedBy: ownerId,
            ownerId: ownerId,
        });

        const projectItemsCollectionReference = db
            .collection('projectitems')
            .where('projectId', '==', payload.projectId);

        const projectItemsQuerySnapshot = yield call(
            rsf.firestore.getCollection,
            projectItemsCollectionReference
        );

        // Create a batched write operation
        const batch = db.batch();

        // Update createdBy and lastModifiedBy on each projectitem document
        projectItemsQuerySnapshot.forEach((projectItem) => {
            const projectItemRef = db.collection('projectitems').doc(projectItem.id);
            batch.update(projectItemRef, {
                createdBy: ownerId,
                lastModifiedBy: ownerId,
            });
        });

        // Commit the batched write operation
        yield call([batch, batch.commit]);
    } catch (error) {
        yield put(projectsActions.createSyncProjectFailed({ syncError: error }));
    }
}

function* shareProjectWithHo({ payload }) {
    const projectId = payload.projectId;
    const ownerId = payload.ownerId;
    const currentUserId = payload.currentUserId;
    try {
        // Update project document
        yield call(rsf.firestore.updateDocument, `projects/${projectId}`, {
            lastModifiedBy: currentUserId,
            ownerId: ownerId,
        });
        if (ownerId !== '') {
            yield call(sendEmailForHomeOwner, { email: ownerId });
        }
        yield put(projectsActions.createShareProjectWithHoSuccess());
    } catch (error) {
        yield put(projectsActions.createShareProjectWithHoFailed({ error }));
    }
}

export default function* projectsSaga() {
    yield all([
        takeEvery(projectsActions.LOAD_PROJECTS_START, loadUserProjectsSaga),
        takeEvery(projectsActions.CREATE_PROJECT_START, createProjectSaga),
        takeEvery(projectsActions.CREATE_PROJECT_BY_WIZARD_START, createProjectByWizardSaga),
        takeEvery(projectsActions.CREATE_CATEGORY_PROJECT_START, createCategoryProjectSaga),
        takeEvery(
            projectsActions.CREATE_PROJECT_WITH_CALCULATION_START,
            createProjectWithCalculationSaga
        ),
        takeEvery(projectsActions.EDIT_PROJECT_START, editProjectSaga),
        takeEvery(projectsActions.ASSIGN_HO_START, assignHoSaga),
        takeEvery(projectsActions.DELETE_PROJECT_START, deleteProjectSaga),
        takeEvery(projectsActions.SYNC_PROJECT_START, synchProjectSaga),
        takeEvery(projectsActions.CLEAR_FLAGS, clearFlags),
        takeEvery(projectsActions.CLEAR_SELECTED_PROJECT_START, clearSelectedProject),
        takeEvery(projectsActions.LOAD_PROJECT_DETAILS_START, loadProjectDetailsSaga),
        takeEvery(projectsActions.SET_SELECTED_TAB_START, createSetSelectedTabSaga),
        takeEvery(projectsActions.SET_ACTION_NAME_START, createSetActionNameSaga),
        takeEvery(
            projectsActions.ASSIGN_ANONYMOUS_PROJECT_TO_CURRENT_USER_START,
            assignAnonymousProjectToCurrentUser
        ),
        takeEvery(projectsActions.SHARE_PROJECT_WITH_HO_START, shareProjectWithHo),
    ]);
}
